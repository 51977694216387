export const FLOATER = "floater";
export const INDIVIDUAL = "individual";
export const SELF = "self";
export const OTHER_DISEASE = "Other Disease";
export const QUOTE_CREATED = "quote_created";
export const PROPOSAL_SUMMARY = "proposal-summary";
export const RENEWAL_KYC_VERIFICATION_PROGRESS = "KYC Verification In Progress";
export const RENEWAL_KYC_VERIFICATION_REJECTED = "KYC Verification Failed";
export const KYC_PENDING = "KYC Pending";
export const KYC_COMPLETED_PAYMENT_PENDING = "KYC Completed/Payment Pending";
export const KYC_REJECTED = "KYC Rejected";
export const PREMIUM = "premium";
export const PED = "ped";
export const PROPOSAL_DETAILS = "proposal_details";
export const CONTACT_DETAIL = "contact_details";
export const AGS_PREFIX = "ags";
export const TOPUP_PREFIX = "topup";
export const HEALTH_PREFIX = "health";
export const RELATION_SELF = "primary insured";
export const CHOOSE_PLAN_QUICK_SUMMARY = "plan-quick-summary";
export const CONSENT = "consent";
export const CONSENT_PAYMENT = "consent_payment";
export const PAYMENT = "payment";
export const CAR_PREFIX = "car";
// Special character removal regex

// eslint-disable-next-line no-useless-escape
export const SpecialRegex = /[!@#$%^&*()_+\-=\[\]{};`'~:"\\|,.<>\/?0-9]+/;
export const NameRegex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
// eslint-disable-next-line no-useless-escape
export const SpecialRegexWithNumbers = /[!@#$%^&*()_+\-=\[\]{};`'~:"\\|,<>\/?]+/;

export const IfscRegex = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;

export const DISEASES_ARRAY =
  "Heart Disease,Asthma,Diabetes,Epilepsy/Seizures/Fits,Hypertension/High BP,High Cholesterol,Liver diseases (Cirrhosis, Jaundice, Hepatitis),Kidney Disorder (Stone,Infection,Failure,Polyp),Thyroid Disorder,Cancer";
export const TOPUP_INDIVIDUAL_MEMBERS = [
  "primary insured",
  "spouse",
  "child1",
  "child2",
  "child3",
  "mother",
  "father",
  "mother-in-law",
  "father-in-law",
  "grandfather",
  "grandmother",
  "brother1",
  "sister1",
  "niece1",
  "nephew1",
  "grandchild/children1",
  "brother2",
  "sister2",
  "niece2",
  "nephew2",
  "grandchild/children2",
  "brother-in-law",
  "sister-in-law",
  "son-in-law",
  "daughter-in-law",
];

export const TOPUP_INDIVIDUAL_MAX_MEMBERS_LIMIT = 8;

export const TOPUP_FLOATER_MEMBERS = [
  "primary insured",
  "spouse",
  "child1",
  "child2",
  "child3",
  "mother",
  "father",
  "mother-in-law",
  "father-in-law",
];
export const TOPUP_FLOATER_MAX_MEMBERS_LIMIT = 9;

export const MALE = "male";
export const FEMALE = "female";
export const TRANSGENDER = "transgender";

export const SI = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "15",
  "20",
  "25",
  "50",
  "75",
  "100",
  "150",
  "250",
  "500",
];

export const SiTopup = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "15",
  "20",
];

export const BRS = {
  1: ["0.5"],
  2: ["0.5", "1"],
  3: ["0.5", "1", "1.5", "2"],
  4: ["0.5", "1", "1.5", "2", "2.5", "3"],
  5: ["0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"],
  6: ["0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"],
  7: ["0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"],
  8: ["0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"],
  9: [
    "0.5",
    "1",
    "1.5",
    "2",
    "2.5",
    "3",
    "3.5",
    "4",
    "4.5",
    "5",
    "5.5",
    "6",
    "6.5",
    "7",
    "7.5",
    "8",
  ],
  10: [
    "0.5",
    "1",
    "1.5",
    "2",
    "2.5",
    "3",
    "3.5",
    "4",
    "4.5",
    "5",
    "5.5",
    "6",
    "6.5",
    "7",
    "7.5",
    "8",
    "8.5",
    "9",
  ],
  15: ["1", "2", "3", "4", "5", "7.5", "10"],
  20: ["1", "2", "3", "4", "5", "7.5", "10", "15"],
  25: ["1", "2", "3", "4", "5", "7.5", "10", "15", "20"],
  50: ["5", "7.5", "10", "15", "20", "25", "30", "35", "40", "45"],
  75: ["7.5", "10", "20", "30", "40", "50", "60"],
  100: ["5", "7.5", "10", "20", "30", "40", "50", "60", "70", "80", "90"],
  150: ["7.5", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
  200: ["7.5", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
  250: ["7.5", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
  500: ["7.5", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
};

export const RUPEE_SYMBOL = "₹";

export const BORN_CARE_WITH_MATERNITY = "New Born Care With Maternity";
export const BORN_CATE_WITH_SUM_INSURED = "New Born Care Within SI";

export const TEMPORARY_EXCLUSION_DISEASES = [
  "Asthma",
  "Hypertension/High BP",
  "Diabetes",
  "High Cholesterol",
  "Thyroid Disorder",
  "Dyslipidaemia",
  "Cataract Of Eyes",
  "Varicose Veins",
  "Glaucoma",
  "Polycystic Ovaries",
];

export const PERMANENT_EXCLUSION_DISEASES = ["Epilepsy/Seizures/Fits"];

export const CHILD_ARRAY = [
  "child1",
  "child2",
  "child3",
  "grandchild/children",
];
