import axios from "axios";

import { apiUrl, parseHTML } from "./helper";

const api = axios.create({
  baseURL: `${apiUrl}`,
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      // eslint-disable-next-line prefer-template
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

api.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    // console.log(tmpConfig);
    tmpConfig.withCredentials = true;
    tmpConfig.crossDomain = true;
    tmpConfig.defaults = {};
    tmpConfig.defaults.withCredentials = true;
    // tmpConfig.headers.Authorization = `Bearer ${getToken()}`;
    tmpConfig.headers["X-CSRFToken"] = getCookie("csrftoken");
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (parseHTML(response.data).toLowerCase() === "forbidden") {
      window.location.href = process.env.REACT_APP_LOGOUT_URL;
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL;
    }
    return Promise.reject(error);
  }
);

export default api;
